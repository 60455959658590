import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';

export const selectGetCustomersAccount = (state: RootState) => state.customers.account.getCustomersAccountState;
export const selectGetCustomersAccountData = (state: RootState) =>
  state.customers.account.getCustomersAccountState.data;
export const selectGetCustomersAccountStatus = (state: RootState) =>
  state.customers.account.getCustomersAccountState.status;
export const selectUpdateCustomerById = (state: RootState) => state.customers.account.updateCustomerByIdState;
export const selectGetCustomerById = (state: RootState) => state.customers.account.getCustomerByIdState;

export const selectGetCustomersAccountOptions = createDraftSafeSelector(
  selectGetCustomersAccountStatus,
  selectGetCustomersAccountData,
  (status, data) => {
    if (status === 'success') {
      return data.payload.map((item: any) => ({ label: item.first_name + item.last_name, value: item.account_number }));
    } else {
      return [];
    }
  }
);
