import { combineReducers } from '@reduxjs/toolkit';
import baseReducer from './base';
import customerAccountReducer from './accounts';

const customerReducer = combineReducers({
  base: baseReducer,
  account: customerAccountReducer,
});

export default customerReducer;
