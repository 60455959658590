import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { TFulfilledAction, TPendingAction, TRejectedAction } from 'types/store';
import {
  commonInitialState,
  handleFulfilledMatcherCase,
  handlePendingMatcherCase,
  handleRejectedMatcherCase,
} from 'utils';
import { getBillingSummaryByDistrict, getBillingSummaryByRegion, getBillingSummaryByRoute } from './api';

const namespace = 'billing/dashboard';
export const matcherNameSpace = namespace + '/matcher';

// Reducer Matcher Case
function isPendingAction(action: AnyAction): action is TPendingAction {
  return action.type.includes(matcherNameSpace) && action.type.endsWith('pending');
}

function isFulfilledAction(action: AnyAction): action is TFulfilledAction {
  return action.type.includes(matcherNameSpace) && action.type.endsWith('fulfilled');
}

function isRejectedAction(action: AnyAction): action is TRejectedAction {
  return action.type.includes(matcherNameSpace) && action.type.endsWith('rejected');
}

const initialState: Record<string, ISliceState<any>> = {
  getDashboardSummaryState: commonInitialState,
  getDashboardReportState: commonInitialState,
  getHighestRevenueState: commonInitialState,
  getGeneratedBillsState: commonInitialState,
  getBillingSummaryState: commonInitialState,
  finalizeBillState: commonInitialState,
  generateBillsState: commonInitialState,
  getBillPeriodsState: commonInitialState,
  sendBillAttachementState: commonInitialState,
  downloadBillInvoiceState: commonInitialState,
  downloadAllBillInvoiceState: commonInitialState,
  getUnbilledCustomersState: commonInitialState,
  getBillingSummaryByRegionState: commonInitialState,
  getBillingSummaryByRouteState: commonInitialState,
  getBillingSummaryByDistrictState: commonInitialState,
};

// Slice
const dashboard = createSlice({
  name: namespace,
  initialState: initialState,
  reducers: {
    resetGetBillingSummary: state => {
      state.getBillingSummaryState = commonInitialState;
    },
    resetGetDashboardSummary: state => {
      state.getDashboardSummaryState = commonInitialState;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(isPendingAction, handlePendingMatcherCase)
      .addMatcher(isFulfilledAction, handleFulfilledMatcherCase)
      .addMatcher(isRejectedAction, handleRejectedMatcherCase)
      .addDefaultCase(state => state);
  },
});

export const { resetGetBillingSummary, resetGetDashboardSummary } = dashboard.actions;

export default dashboard.reducer;
