import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { TFulfilledAction, TPendingAction, TRejectedAction } from 'types/store';
import {
  handleFulfilledMatcherCase,
  handlePendingMatcherCase,
  handleRejectedMatcherCase,
  commonInitialState,
} from 'utils';

export const namespace = 'users';
export const matcherNamespace = 'users/matcher';

// Reducer Matcher Case
function isPendingAction(action: AnyAction): action is TPendingAction {
  return action.type.includes(matcherNamespace) && action.type.endsWith('pending');
}

function isFulfilledAction(action: AnyAction): action is TFulfilledAction {
  return action.type.includes(matcherNamespace) && action.type.endsWith('fulfilled');
}

function isRejectedAction(action: AnyAction): action is TRejectedAction {
  return action.type.includes(matcherNamespace) && action.type.endsWith('rejected');
}

const initialState: Record<string, ISliceState<any> | any> = {
  getUsersState: commonInitialState,
  getInactiveUsersState: commonInitialState,
  userByIdState: commonInitialState,
  getUserHistoriesByIdState: commonInitialState,
  saveUserState: commonInitialState,
  updateUserActivationByIdState: commonInitialState,
  updateMultipleUsersActivationState: commonInitialState,
  deleteUserByIdState: commonInitialState,
  deleteMultipleUsersState: commonInitialState,
  getDistrictsState: commonInitialState,
  getTechniciansState: commonInitialState,
  saveTechnicianState: commonInitialState,
  technicianByIdState: commonInitialState,
  deleteTechnicianByIdState: commonInitialState,
};

// Slice
const usersApiSlice = createSlice({
  name: namespace,
  initialState: initialState,
  reducers: {
    resetGetUsers: state => {
      state.getUsersState = commonInitialState;
    },
    resetGetInactiveUsers: state => {
      state.getInactiveUsersState = commonInitialState;
    },
    resetUserByIdState: state => {
      state.userByIdState = commonInitialState;
    },
    resetGetTechnicians: state => {
      state.getTechniciansState = commonInitialState;
    },
    resetTechnicianByIdState: state => {
      state.technicianByIdState = commonInitialState;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(isPendingAction, handlePendingMatcherCase)
      .addMatcher(isFulfilledAction, handleFulfilledMatcherCase)
      .addMatcher(isRejectedAction, handleRejectedMatcherCase)
      .addDefaultCase(state => state);
  },
});

export const {
  resetUserByIdState,
  resetGetUsers,
  resetGetInactiveUsers,
  resetGetTechnicians,
  resetTechnicianByIdState,
} = usersApiSlice.actions;
const userReducer = usersApiSlice.reducer;
export default userReducer;
