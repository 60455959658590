import { RootState } from 'store/rootReducer';

export const selectGetDashboardSummary = (state: RootState) => state.billing.dashboard.getDashboardSummaryState;
export const selectGetDashboardReport = (state: RootState) => state.billing.dashboard.getDashboardReportState;
export const selectGetHighestRevenue = (state: RootState) => state.billing.dashboard.getHighestRevenueState;
export const selectGetGeneratedBills = (state: RootState) => state.billing.dashboard.getGeneratedBillsState;
export const selectGetBillingSummary = (state: RootState) => state.billing.dashboard.getBillingSummaryState;
export const selectGetBillingSummaryByRegion = (state: RootState) =>
  state.billing.dashboard.getBillingSummaryByRegionState;
export const selectGetBillingSummaryByRoute = (state: RootState) =>
  state.billing.dashboard.getBillingSummaryByRouteState;
export const selectGetBillingSummaryByDistrict = (state: RootState) =>
  state.billing.dashboard.getBillingSummaryByDistrictState;
export const selectfinalizeBill = (state: RootState) => state.billing.dashboard.finalizeBillState;
export const selectGenerateBills = (state: RootState) => state.billing.dashboard.generateBillsState;
export const selectGetBillPeriods = (state: RootState) => state.billing.dashboard.getBillPeriodsState;
export const selectSendBillAttachement = (state: RootState) => state.billing.dashboard.sendBillAttachementState;
export const selectDownloadBillInvoice = (state: RootState) => state.billing.dashboard.downloadBillInvoiceState;
export const selectDownloadAllBillInvoice = (state: RootState) => state.billing.dashboard.downloadAllBillInvoiceState;
export const selectGetUnbilledCustomers = (state: RootState) => state.billing.dashboard.getUnbilledCustomersState;
