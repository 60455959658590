import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export function initSentry() {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
      release: process.env.REACT_APP_SENTRY_RELEASE,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
}

/**
 * Catch global errors and send the caught error to Sentry.
 *
 * @param {Object} error
 * @param {Object} errorInfo
 */
export function catchErrorsWithScope(error: Error, errorInfo: any) {
  if (process.env.NODE_ENV === 'production') {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }
}

export function WithSentryProfiler(component: React.FunctionComponent) {
  if (process.env.NODE_ENV === 'production') {
    return Sentry.withProfiler(component);
  } else return component;
}
