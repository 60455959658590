import { createAsyncThunk } from '@reduxjs/toolkit';
import { matcherNamespace } from './slice';
import endpoints from 'global/endpoints';
import apiService from 'service/apiService';
import { IGetUsersSearchQuery } from 'pages/Customer/Shared/types';
import { encodeQuery, isEmptyObject } from 'utils';

export const getCustomers = createAsyncThunk(
  matcherNamespace + '/[getCustomers]',
  async (searchQuery: IGetUsersSearchQuery) => {
    const getCustomersEndPoint = isEmptyObject(searchQuery)
      ? endpoints.customers.customers
      : endpoints.customers.customers + '?' + encodeQuery(searchQuery);
    return await apiService({}).get(getCustomersEndPoint);
  }
);

export const getCustomerById = createAsyncThunk(matcherNamespace + '/[customerById]', async (data: { id: string }) => {
  const getCustomerById = endpoints.customers.customerById.replace('{customerId}', data.id);
  return await apiService({}).get(getCustomerById);
});

export const getCustomerStatus = createAsyncThunk(
  matcherNamespace + '/[getCustomerStatus]',
  async (searchQuery: any = {}) => {
    const customerStatusEndpoint = isEmptyObject(searchQuery)
      ? endpoints.customers.customerStatus
      : endpoints.customers.customerStatus + '?' + encodeQuery(searchQuery);
    return await apiService({}).get(customerStatusEndpoint);
  }
);
