import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';

export const selectGetCustomers = (state: RootState) => state.customers.base.getCustomersState;
export const selectGetCustomersData = (state: RootState) => state.customers.base.getCustomersState.data;
export const selectGetCustomersStatus = (state: RootState) => state.customers.base.getCustomersState.status;
export const selectCustomerById = (state: RootState) => state.customers.base.customerByIdState;
export const selectGetCustomerStatus = (state: RootState) => state.customers.base.getCustomerStatusState;
export const selectGetCustomerStatusData = (state: RootState) => state.customers.base.getCustomerStatusState.data;
export const selectGetCustomerStatusStatus = (state: RootState) => state.customers.base.getCustomerStatusState.status;

export const selectGetCustomersOptions = createDraftSafeSelector(
  selectGetCustomersStatus,
  selectGetCustomersData,
  (status, data) => {
    if (status === 'success') {
      return data.payload.map((item: any) => ({ label: item.region_name, value: item.id }));
    } else {
      return [];
    }
  }
);

export const selectGetCustomerStatusOptions = createDraftSafeSelector(
  selectGetCustomerStatusStatus,
  selectGetCustomerStatusData,
  (status, data) => {
    if (status === 'success') {
      return data.payload.map((item: any) => ({ label: item.name, value: item.code }));
    } else {
      return [];
    }
  }
);
