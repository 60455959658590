import { RootState } from 'store/rootReducer';

export const selectGetRecievedGWCLPaypoints = (state: RootState) =>
  state.payments.recievedPayments.getRecievedGWCLPaypointsState;
export const selectGetRecievedGWCLPaypointsSummary = (state: RootState) =>
  state.payments.recievedPayments.getRecievedGWCLPaypointsSummaryState;
export const selectGetRecievedElectronicPayments = (state: RootState) =>
  state.payments.recievedPayments.getRecievedElectronicPaymentsState;
export const selectGetRecievedElectronicPaymentsSummary = (state: RootState) =>
  state.payments.recievedPayments.getRecievedElectronicPaymentsSummaryState;
export const selectGetRecievedThirdPartyPaypoints = (state: RootState) =>
  state.payments.recievedPayments.getRecievedThirdPartyPaypointsState;
export const selectGetRecievedThirdPartyPaypointsSummary = (state: RootState) =>
  state.payments.recievedPayments.getRecievedThirdPartyPaypointsSummaryState;
