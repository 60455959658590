import { createAsyncThunk } from '@reduxjs/toolkit';
import endpoints from 'global/endpoints';
import apiService from 'service/apiService';
import { encodeQuery, isEmptyObject } from 'utils';
import { namespace } from './slice';

const receivedPayments = endpoints.payments.receievedPayments;

export const getRecievedGWCLPaypoints = createAsyncThunk(
  namespace + '/[getRecievedGWCLPaypoints]',
  async (searchQuery: any) => {
    const endpoints = isEmptyObject(searchQuery)
      ? receivedPayments.gwclPaypoint
      : receivedPayments.gwclPaypoint + '?' + encodeQuery(searchQuery);
    const response = await apiService({}).get(endpoints);
    return response;
  }
);

export const getRecievedGWCLPaypointsSummary = createAsyncThunk(
  namespace + '/[getRecievedGWCLPaypointsSummary]',
  async (searchQuery: any) => {
    const endpoints = isEmptyObject(searchQuery)
      ? receivedPayments.gwclPaypointSummary
      : receivedPayments.gwclPaypointSummary + '?' + encodeQuery(searchQuery);
    const response = await apiService({}).get(endpoints);
    return response;
  }
);

export const getRecievedElectronicPayments = createAsyncThunk(
  namespace + '/[getRecievedElectronicPayments]',
  async (searchQuery: any) => {
    const endpoints = isEmptyObject(searchQuery)
      ? receivedPayments.electronic
      : receivedPayments.electronic + '?' + encodeQuery(searchQuery);
    const response = await apiService({}).get(endpoints);
    return response;
  }
);

export const getRecievedElectronicPaymentsSummary = createAsyncThunk(
  namespace + '/[getRecievedElectronicPaymentsSummary]',
  async (searchQuery: any) => {
    const endpoints = isEmptyObject(searchQuery)
      ? receivedPayments.electronicSummary
      : receivedPayments.electronicSummary + '?' + encodeQuery(searchQuery);
    const response = await apiService({}).get(endpoints);
    return response;
  }
);

export const getRecievedThirdPartyPaypoints = createAsyncThunk(
  namespace + '/[getRecievedThirdPartyPaypoints]',
  async (searchQuery: any) => {
    const endpoints = isEmptyObject(searchQuery)
      ? receivedPayments.thirdPartyPaypoint
      : receivedPayments.thirdPartyPaypoint + '?' + encodeQuery(searchQuery);
    const response = await apiService({}).get(endpoints);
    return response;
  }
);

export const getRecievedThirdPartyPaypointsSummary = createAsyncThunk(
  namespace + '/[getRecievedThirdPartyPaypointsSummary]',
  async (searchQuery: any) => {
    const endpoints = isEmptyObject(searchQuery)
      ? receivedPayments.thirdPartyPaypointSummary
      : receivedPayments.thirdPartyPaypointSummary + '?' + encodeQuery(searchQuery);
    const response = await apiService({}).get(endpoints);
    return response;
  }
);
