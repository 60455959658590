import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { TFulfilledAction, TPendingAction, TRejectedAction } from 'types/store';
import { handleFulfilledMatcherCase, handlePendingMatcherCase, handleRejectedMatcherCase } from 'utils';

const namespace = 'devices/meters';
export const matcherNameSpace = 'devices/meters/matcher';

// Reducer Matcher Case
function isPendingAction(action: AnyAction): action is TPendingAction {
  return action.type.includes(matcherNameSpace) && action.type.endsWith('pending');
}

function isFulfilledAction(action: AnyAction): action is TFulfilledAction {
  return action.type.includes(matcherNameSpace) && action.type.endsWith('fulfilled');
}

function isRejectedAction(action: AnyAction): action is TRejectedAction {
  return action.type.includes(matcherNameSpace) && action.type.endsWith('rejected');
}

// INITIAL STATE
const initialCommonState = { status: null, data: null };

const initialState: Record<string, ISliceState<any>> = {
  getMetersState: initialCommonState,
  getMeterByIdState: initialCommonState,
  deleteMeterByIdState: initialCommonState,
  getMeterByMeterNumberState: initialCommonState,
  uploadMetersCsvState: initialCommonState,
  getMeterDetailState: initialCommonState,
  changeValveStatusByMeterNumberState: initialCommonState,
  saveMeterState: initialCommonState,

  // meter details
  getMeterBrandsState: initialCommonState,
  getMeterModelsState: initialCommonState,
  getMeterSizesState: initialCommonState,
  getMeterTypesState: initialCommonState,
  getMeterStatusState: initialCommonState,
  getMeterStatesState: initialCommonState,
  getMeterInstallStagesState: initialCommonState,
  getMeterReadingsState: initialCommonState,
  getMeterInstallationHistoryState: initialCommonState,
  getMeterMappedInstallationHistoryState: initialCommonState,
  getMeterInitialFinalReadingsState: initialCommonState,

  //meter history
  getMeterOnlineHistoryState: initialCommonState,
  getMeterAddressHistoryState: initialCommonState,

  readMeterFromTCPState: initialCommonState,
};

// Slice
const metersApiSlice = createSlice({
  name: namespace,
  initialState: initialState,
  reducers: {
    resetGetMetersState: state => {
      state.getMetersState = initialCommonState;
    },
    resetGetMeterByIdState: state => {
      state.getMeterByIdState = initialCommonState;
    },
    resetDeleteMeterByIdState: state => {
      state.deleteMeterByIdState = initialCommonState;
    },
    resetUploadMetersCsvState: state => {
      state.uploadMetersCsvState = initialCommonState;
    },
    resetGetMeterByMeterNumberState: state => {
      state.getMeterByMeterNumberState = initialCommonState;
    },
    resetChangeValveStatusByMeterNumberState: state => {
      state.openValveByMeterNumberState = initialCommonState;
    },
    resetSaveMeterState: state => {
      state.saveMeterState = initialCommonState;
    },
    resetGetMeterBrandsState: state => {
      state.getMeterBrandState = initialCommonState;
    },
    resetGetMeterModelsState: state => {
      state.getMeterModelsState = initialCommonState;
    },
    resetGetMeterTypesState: state => {
      state.getMeterTypesState = initialCommonState;
    },
    resetGetMeterSizesState: state => {
      state.getMeterSizesState = initialCommonState;
    },
    resetGetMeterStatusState: state => {
      state.getMeterStatusState = initialCommonState;
    },
    resetGetMeterStatesState: state => {
      state.getMeterStatesState = initialCommonState;
    },
    resetGetMeterInstallStagesState: state => {
      state.getMeterInstallStagesState = initialCommonState;
    },
    resetGetMeterReadingsState: state => {
      state.getMetersReadingsState = initialCommonState;
    },
    resetGetMeterInstallationHistoryState: state => {
      state.getMeterInstallationHistoryState = initialCommonState;
    },
    resetGetMeterMappedInstallationHistoryState: state => {
      state.getMeterMappedInstallationHistoryState = initialCommonState;
    },
    resetGetMeterInitialFinalReadingsState: state => {
      state.getMeterInitialFinalReadingsState = initialCommonState;
    },
    resetGetMeterOnlineHistoryState: state => {
      state.getMeterOnlineHistoryState = initialCommonState;
    },
    resetGetMeterAddressHistoryState: state => {
      state.getMeterAddressHistoryState = initialCommonState;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(isPendingAction, handlePendingMatcherCase)
      .addMatcher(isFulfilledAction, handleFulfilledMatcherCase)
      .addMatcher(isRejectedAction, handleRejectedMatcherCase)
      .addDefaultCase(state => state);
  },
});

export const {
  resetGetMetersState,
  resetGetMeterByIdState,
  resetGetMeterByMeterNumberState,
  resetDeleteMeterByIdState,
  resetUploadMetersCsvState,
  resetChangeValveStatusByMeterNumberState,
  resetSaveMeterState,
  resetGetMeterBrandsState,
  resetGetMeterModelsState,
  resetGetMeterTypesState,
  resetGetMeterSizesState,
  resetGetMeterStatusState,
  resetGetMeterStatesState,
  resetGetMeterInstallStagesState,
  resetGetMeterReadingsState,
  resetGetMeterInstallationHistoryState,
  resetGetMeterMappedInstallationHistoryState,
  resetGetMeterInitialFinalReadingsState,
  resetGetMeterOnlineHistoryState,
  resetGetMeterAddressHistoryState,
} = metersApiSlice.actions;
export default metersApiSlice.reducer;
