import { createAsyncThunk } from '@reduxjs/toolkit';
import { matcherNamespace } from './slice';
import endpoints from 'global/endpoints';
import apiService from 'service/apiService';
import { IGetUsersSearchQuery } from 'pages/Customer/Shared/types';
import { encodeQuery, isEmptyObject } from 'utils';

export const getCustomersAccount = createAsyncThunk(
  matcherNamespace + '/[getCustomersAccount]',
  async (searchQuery: IGetUsersSearchQuery) => {
    const getCustomersEndPoint = isEmptyObject(searchQuery)
      ? endpoints.customers.customersAccount
      : endpoints.customers.customersAccount + '?' + encodeQuery(searchQuery);
    return await apiService({}).get(getCustomersEndPoint);
  }
);

export const updateCustomerById = createAsyncThunk(
  matcherNamespace + '/[updateCustomerById]',
  async (data: { id: string; value: any }) => {
    const patchEndPoints = endpoints.customers.customerAccountById.replace('{customerId}', data.id);
    const response = await apiService({}).put(patchEndPoints, data);
    return { ...response, showSuccessToast: true };
  }
);

export const getCustomerById = createAsyncThunk(
  matcherNamespace + '/[getCustomerById]',
  async (data: { id: string }) => {
    const customerAccountEndpoint = endpoints.customers.accountById.replace('{customerId}', data.id);
    return await apiService({}).get(customerAccountEndpoint);
  }
);
