import { createAsyncThunk } from '@reduxjs/toolkit';
import endpoints from 'global/endpoints';
import apiService from 'service/apiService';
import { encodeQuery, isEmptyObject } from 'utils';
import { matcherNameSpace } from './slice';
import { IDashboardSummay } from './types';

const billingEndpoints = endpoints.billing.dashboard;

export const getDashboardSummary = createAsyncThunk(
  matcherNameSpace + '/[getDashboardSummary]',
  async (searchQuery: IDashboardSummay, { signal }) => {
    const getUsersEndPoint = isEmptyObject(searchQuery)
      ? billingEndpoints.dashboardSummary
      : billingEndpoints.dashboardSummary + '?' + encodeQuery(searchQuery);
    const response = await apiService({}).get(getUsersEndPoint, { signal: signal });
    return response;
  }
);

export const getDashboardReport = createAsyncThunk(matcherNameSpace + '/[getDashboardReport]', async () => {
  const response = await apiService({}).get(billingEndpoints.dashboardReport);
  return response;
});

export const getHighestRevenue = createAsyncThunk(matcherNameSpace + '/[getHighestRevenue]', async () => {
  const response = await apiService({}).get(billingEndpoints.highestRevenue);
  return response;
});

export const getGeneratedBills = createAsyncThunk(
  matcherNameSpace + '/[getGeneratedBills]',
  async (searchQuery: any = {}) => {
    const getGenerateBillsEndpoint = isEmptyObject(searchQuery)
      ? billingEndpoints.generatedBills
      : billingEndpoints.generatedBills + '?' + encodeQuery(searchQuery);
    const response = await apiService({}).get(getGenerateBillsEndpoint);
    return response;
  }
);

export const billingSummary = async (searchQuery: any) => {
  const getBillingSummaryEndpoint = isEmptyObject(searchQuery)
    ? billingEndpoints.billingSummary
    : billingEndpoints.billingSummary + '?' + encodeQuery(searchQuery);
  return await apiService({}).get(getBillingSummaryEndpoint);
};

export const getBillingSummary = createAsyncThunk(matcherNameSpace + '/[getBillingSummary]', billingSummary);

export const getBillingSummaryByRegion = createAsyncThunk(
  matcherNameSpace + '/[getBillingSummaryByRegion]',
  billingSummary
);

export const getBillingSummaryByRoute = createAsyncThunk(
  matcherNameSpace + '/[getBillingSummaryByRoute]',
  billingSummary
);

export const getBillingSummaryByDistrict = createAsyncThunk(
  matcherNameSpace + '/[getBillingSummaryByDistrict]',
  billingSummary
);

export const finalizeBill = createAsyncThunk(matcherNameSpace + '/[finalizeBill]', async (billPeriodId: any) => {
  return await apiService({}).post(billingEndpoints.finalizeBill, billPeriodId);
});

export const generateBills = createAsyncThunk(matcherNameSpace + '/[generateBills]', async () => {
  return await apiService({}).post(billingEndpoints.generateBills, {});
});

export const getBillPeriods = createAsyncThunk(matcherNameSpace + '/[getBillPeriods]', async () => {
  return await apiService({}).get(billingEndpoints.billPeriods);
});

export const sendBillAttachement = createAsyncThunk(matcherNameSpace + '/[sendBillAttachement]', async (data: any) => {
  const response = await apiService({}).post(billingEndpoints.sendBillAttachement, data);
  return { ...response, showSuccessToast: true };
});

export const downloadBillInvoice = createAsyncThunk(matcherNameSpace + '/[downloadBillInvoice]', async (id: string) => {
  const endpoints = billingEndpoints.downloadBillInvoice.replace('{billingDetailId}', id);
  return await apiService({}).get(endpoints, { responseType: 'blob' });
});

export const downloadAllBillInvoice = createAsyncThunk(
  matcherNameSpace + '/[downloadAllBillInvoice]',
  async (data: any, { rejectWithValue }) => {
    try {
      const { route_id = '', bill_period_code = '', meter_status = '' } = data;
      const endpoints =
        billingEndpoints.downloadAllBillInvoice
          .replace('{routeId}', route_id)
          .replace('{billPeriodCode}', bill_period_code) +
        '?' +
        encodeQuery({ meter_status });
      return await apiService({}).get(endpoints, { responseType: 'blob' });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUnbilledCustomers = createAsyncThunk(
  matcherNameSpace + '/[getUnbilledCustomers]',
  async (searchQuery: any = {}) => {
    const getUnbilledCustomersEndpoint = isEmptyObject(searchQuery)
      ? billingEndpoints.unbilledCustomers
      : billingEndpoints.unbilledCustomers + '?' + encodeQuery(searchQuery);
    return await apiService({}).get(getUnbilledCustomersEndpoint);
  }
);
