import { RootState } from 'store/rootReducer';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const selectGetMetersState = (state: RootState) => state.device.meters.getMetersState;
export const selectGetMeterByIdState = (state: RootState) => state.device.meters.getMeterByIdState;
export const selectDeleteMeterByIdState = (state: RootState) => state.device.meters.deleteMeterByIdState;
export const selectGetMeterByMeterNumberState = (state: RootState) => state.device.meters.getMeterByMeterNumberState;
export const selectUploadMetersCsvState = (state: RootState) => state.device.meters.uploadMetersCsvState;
export const selectChangeValveStatusByMeterNumberState = (state: RootState) =>
  state.device.meters.changeValveStatusByMeterNumberState;
export const selectSaveMeterState = (state: RootState) => state.device.meters.saveMeterState;
export const selectGetMeterReadingsState = (state: RootState) => state.device.meters.getMeterReadingsState;
export const selectGetMeterInitialFinalReadingsState = (state: RootState) =>
  state.device.meters.getMeterInitialFinalReadingsState;
export const selectGetMeterInstallationHistoryState = (state: RootState) =>
  state.device.meters.getMeterInstallationHistoryState;
export const selectGetMeterMappedInstallationHistoryState = (state: RootState) =>
  state.device.meters.getMeterMappedInstallationHistoryState;

export const selectGetMeterOnlineHistoryState = (state: RootState) => state.device.meters.getMeterOnlineHistoryState;
export const selectGetMeterAddressHistoryState = (state: RootState) => state.device.meters.getMeterAddressHistoryState;
export const selectReadMeterFromTCP = (state: RootState) => state.device.meters.readMeterFromTCPState;

// Meter brand options
export const selectGetMeterBrandsOptionsStatus = (state: RootState) => state.device.meters.getMeterBrandsState.status;
export const selectGetMeterBrandsOptionsData = (state: RootState) => state.device.meters.getMeterBrandsState.data;
export const selectMeterBrandOptions = createDraftSafeSelector(
  selectGetMeterBrandsOptionsStatus,
  selectGetMeterBrandsOptionsData,
  (status, data: any) => {
    if (status === 'success') {
      return data.payload.map((item: any) => ({ label: item.name, value: item.id }));
    } else {
      return [];
    }
  }
);

// Meter model options
export const selectGetMeterModelsOptionsStatus = (state: RootState) => state.device.meters.getMeterModelsState.status;
export const selectGetMeterModelsOptionsData = (state: RootState) => state.device.meters.getMeterModelsState.data;
export const selectMeterModelOptions = createDraftSafeSelector(
  selectGetMeterModelsOptionsStatus,
  selectGetMeterModelsOptionsData,
  (status, data: any) => {
    if (status === 'success') {
      return data.payload.map((item: any) => ({ label: item.name, value: item.id }));
    } else {
      return [];
    }
  }
);

// Meter size options
export const selectGetMeterSizesOptionsStatus = (state: RootState) => state.device.meters.getMeterSizesState.status;
export const selectGetMeterSizesOptionsData = (state: RootState) => state.device.meters.getMeterSizesState.data;
export const selectMeterSizeOptions = createDraftSafeSelector(
  selectGetMeterSizesOptionsStatus,
  selectGetMeterSizesOptionsData,
  (status, data: any) => {
    if (status === 'success') {
      return data.payload.map((item: any) => ({ label: item.name, value: item.id }));
    } else {
      return [];
    }
  }
);

// Meter type options
export const selectGetMeterTypesOptionsStatus = (state: RootState) => state.device.meters.getMeterTypesState.status;
export const selectGetMeterTypesOptionsData = (state: RootState) => state.device.meters.getMeterTypesState.data;
export const selectMeterTypeOptions = createDraftSafeSelector(
  selectGetMeterTypesOptionsStatus,
  selectGetMeterTypesOptionsData,
  (status, data: any) => {
    if (status === 'success') {
      return data.payload.map((item: any) => ({ label: item.name, value: item.id }));
    } else {
      return [];
    }
  }
);

// Meter status options
export const selectGetMeterStatusOptionsStatus = (state: RootState) => state.device.meters.getMeterStatusState.status;
export const selectGetMeterStatusOptionsData = (state: RootState) => state.device.meters.getMeterStatusState.data;
export const selectMeterStatusOptions = createDraftSafeSelector(
  selectGetMeterStatusOptionsStatus,
  selectGetMeterStatusOptionsData,
  (status, data: any) => {
    if (status === 'success') {
      return data.payload.map((item: any) => ({ label: item.name, value: item.id }));
    } else {
      return [];
    }
  }
);

// Meter state options
export const selectGetMeterStatesOptionsStatus = (state: RootState) => state.device.meters.getMeterStatesState.status;
export const selectGetMeterStatesOptionsData = (state: RootState) => state.device.meters.getMeterStatesState.data;
export const selectMeterStateOptions = createDraftSafeSelector(
  selectGetMeterStatesOptionsStatus,
  selectGetMeterStatesOptionsData,
  (status, data: any) => {
    if (status === 'success') {
      return data.payload.map((item: any) => ({ label: item.name, value: item.id }));
    } else {
      return [];
    }
  }
);

// Meter install stage options
export const selectGetMeterInstallStagesOptionsStatus = (state: RootState) =>
  state.device.meters.getMeterInstallStagesState.status;
export const selectGetMeterInstallStagesOptionsData = (state: RootState) =>
  state.device.meters.getMeterInstallStagesState.data;
export const selectMeterInstallStageOptions = createDraftSafeSelector(
  selectGetMeterInstallStagesOptionsStatus,
  selectGetMeterInstallStagesOptionsData,
  (status, data: any) => {
    if (status === 'success') {
      return data.payload.map((item: any) => ({ label: item.name, value: item.id }));
    } else {
      return [];
    }
  }
);
