/**
 *
 * @param theBlob The file object
 * @param fileName File name
 * @returns {Blob}
 */
export function blobToFile(theBlob: any, fileName: string) {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;

  return theBlob;
}

/**
 * @param extension file extension name
 * @returns {string} file types
 */
export function mimeType(extension: string) {
  switch (extension) {
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

    case 'xls':
      return 'application/vnd.ms-excel';

    default:
      return 'text/csv';
  }
}

export function downloadFile(blob: any, filename: string) {
  const a = document.createElement('a');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
}

export const fileReader = (file: any) => {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error('Problem parsing file'));
    };
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.readAsText(file);
  });
};
