import { isEmptyObject } from './isEmptyObject';

/**
 *
 * @param {Object} errors
 * @param {Function} setFieldError
 */
export function setFormFieldError(errors: any, setFieldError: Function) {
  if (!errors || isEmptyObject(errors)) return;

  for (const [key, value] of Object.entries(errors)) {
    let errorString: any = '';
    if (Array.isArray(value) && value.length) {
      value.forEach(item => {
        errorString = errorString + item;
      });
    } else {
      errorString = value;
    }

    setFieldError(key, { msg: errorString });
  }
}
