import { AnyAction, createSlice } from '@reduxjs/toolkit';
import {
  commonInitialState,
  handleFulfilledMatcherCase,
  handlePendingMatcherCase,
  handleRejectedMatcherCase,
} from 'utils';
import { TFulfilledAction, TPendingAction, TRejectedAction } from 'types/store';

export const namespace = 'customers';
export const matcherNamespace = 'customers/matcher';

const initialState: Record<string, ISliceState<any> | any> = {
  getCustomersState: commonInitialState,
  customerByIdState: commonInitialState,
  getCustomerStatusState: commonInitialState,
};

// Reducer Matcher Case
function isPendingAction(action: AnyAction): action is TPendingAction {
  return action.type.includes(matcherNamespace) && action.type.endsWith('pending');
}

function isFulfilledAction(action: AnyAction): action is TFulfilledAction {
  return action.type.includes(matcherNamespace) && action.type.endsWith('fulfilled');
}

function isRejectedAction(action: AnyAction): action is TRejectedAction {
  return action.type.includes(matcherNamespace) && action.type.endsWith('rejected');
}

const customersApiSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // default matcher case for all api calls
      .addMatcher(isPendingAction, handlePendingMatcherCase)
      .addMatcher(isFulfilledAction, handleFulfilledMatcherCase)
      .addMatcher(isRejectedAction, handleRejectedMatcherCase)
      .addDefaultCase(state => state);
  },
});

const customerReducer = customersApiSlice.reducer;
export default customerReducer;
